import React, { useState } from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';

function encode(data) {
  return Object.keys(data)
    .map((key) => {
      const enKey = encodeURIComponent(key);
      const enData = encodeURIComponent(data[key]);
      return `${enKey}=${enData}`;
    })
    .join('&');
}

const Field = styled.div`
  display: flex;
  justify-content: center;
  &:not(:last-child) {
    margin-bottom: 0.75rem;
  }
`;

const ControlField = styled.div`
  font-size: ${({ theme }) => theme.font.size.xs};
  position: relative;
  text-align: inherit;
`;

const Label = styled.label`
  width: 100%;
  color: ${({ theme }) => theme.color.four};
  display: block;
  font-size: ${({ theme }) => theme.font.size.xs};
`;

const FormInput = styled.input`
  box-shadow: inset 0 0.0625em 0.125em rgba(43, 37, 35, 0.05);
  max-width: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.color.one};
  color: ${({ theme }) => theme.color.five};
  align-items: center;
  border: 1px solid ${({ theme }) => theme.color.one};
  display: inline-flex;
  font-size: ${({ theme }) => theme.font.size.xs};
  height: 2.5em;
  justify-content: flex-start;
  line-height: 1.5;
  padding-bottom: calc(0.5em - 1px);
  padding-left: calc(0.75em - 1px);
  padding-right: calc(0.75em - 1px);
  padding-top: calc(0.5em - 1px);
  position: relative;
  vertical-align: top;
  &:focus {
    border-color: ${({ theme }) => theme.color.four};
    box-shadow: 0 0 0 0.125em rgba(51, 179, 145, 0.25);
    outline: none;
  }
  &:active {
    border-color: ${({ theme }) => theme.color.four};
    box-shadow: 0 0 0 0.125em rgba(51, 179, 145, 0.25);
    outline: none;
  }
  &:hover {
    border-color: ${({ theme }) => theme.color.four};
  }
`;

const FormButton = styled.button`
  font-size: ${({ theme }) => theme.font.size.xs};
  padding: 11px 40px;
  font-weight: 400;
  text-transform: uppercase;
  transition: all 0.2s linear;
  display: inline-block;
  border: 1px solid ${({ theme }) => theme.color.four};
  background: ${({ theme }) => theme.color.four};
  position: relative;
  color: ${({ theme }) => theme.color.one};
  z-index: 1;
  border-radius: 30px;
  margin-bottom: 20px;
  &:focus {
    color: ${({ theme }) => theme.color.four};
    background: ${({ theme }) => theme.color.one};
  }
  &:active {
    color: ${({ theme }) => theme.color.four};
    background: ${({ theme }) => theme.color.one};
  }
  &:hover {
    color: ${({ theme }) => theme.color.four};
    background: ${({ theme }) => theme.color.one};
    border-color: ${({ theme }) => theme.color.one};
    transition: all 0.5s ease-in-out;
  }
`;

const FormTextArea = styled(FormInput)`
  resize: vertical;
  max-height: 40em;
  min-height: 8em;
`;

const ContactForm = () => {
  const [isValidated, setIsValidated] = useState(false);

  const handleChange = (e) => {
    setIsValidated({ ...isValidated, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...isValidated,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error));
  };

  return (
    <form
      name="contact"
      method="post"
      action="/thanks/"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
    >
      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
      <input type="hidden" name="form-name" value="contact" />
      <div hidden>
        <label htmlFor="bot-field">
          Nie wypełniaj:
          <input name="bot-field" id="bot-field" onChange={handleChange} />
        </label>
      </div>
      <Field>
        <Label htmlFor="name">
          Twoje imię
          <ControlField>
            <FormInput
              type="text"
              name="name"
              onChange={handleChange}
              id="name"
              required
            />
          </ControlField>
        </Label>
      </Field>
      <Field>
        <Label htmlFor="email">
          Email
          <ControlField>
            <FormInput
              type="email"
              name="email"
              onChange={handleChange}
              id="email"
              required
            />
          </ControlField>
        </Label>
      </Field>
      <Field>
        <Label htmlFor="message">
          Wiadomość
          <ControlField>
            <FormTextArea
              as="textarea"
              rows="8"
              name="message"
              onChange={handleChange}
              id="message"
              required
            />
          </ControlField>
        </Label>
      </Field>
      <Field>
        <FormButton type="submit" aria-label="Submit Button">
          Wyślij
        </FormButton>
      </Field>
    </form>
  );
};

export default ContactForm;
