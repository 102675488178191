import React from 'react';
import styled from 'styled-components';
import {
  TwitterWithCircle as Twitter,
  FacebookWithCircle as Facebook,
  LinkedinWithCircle as Linkedin,
} from '@styled-icons/entypo-social';

const SocialMediaContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
`;

const IconBox = styled.div`
  display: flex;
  margin-right: 15px;
  color: ${({ theme }) => theme.color.one};
  position: relative;
  transition: color 0.2s 0.1s ease-in-out;
  ::after {
    content: '';
    width: 36px;
    height: 36px;
    position: absolute;
    background-color: ${({ color }) => color};
    border-radius: 50px;
    top: 2px;
    left: 2px;
    z-index: 9;
    transition: background-color 0.2s 0.1s ease-in-out;
  }
  :hover {
    color: ${({ color }) => color};
    ::after {
      background-color: ${({ theme }) => theme.color.one};
    }
  }
  svg {
    z-index: 10;
  }
`;

const ContactSocialMedia = () => {
  return (
    <>
      <SocialMediaContainer>
        <ItemContainer>
          <IconBox color="#4867AA">
            <Facebook size="40px" title="facebook" />
          </IconBox>
        </ItemContainer>
        <ItemContainer>
          <IconBox color="#1DA1F2">
            <Twitter size="40px" title="twitter" />
          </IconBox>
        </ItemContainer>
        <ItemContainer>
          <IconBox color="#1283B9">
            <Linkedin size="40px" title="linkedin" />
          </IconBox>
        </ItemContainer>
      </SocialMediaContainer>
    </>
  );
};

export default ContactSocialMedia;
