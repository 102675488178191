import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import IndexPageTemplate from './indexPageTemplate';

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'LocalBusiness',
    name: 'Skup palet',
    // image:
    //   'https://paletypruszczgdanski.pl/static/c418859536273fb32333b2acb8a6d10e/80f52/euro1.avif',
    url: 'https://paletypruszczgdanski.pl/',
    telephone: '731 461 112',
    address: {
      '@type': 'PostalAddress',
      streetAddress: 'Batalionów Chłopskich 3',
      addressLocality: 'Pruszcz Gdański',
      postalCode: '83-000',
      addressCountry: 'PL',
    },
    geo: {
      '@type': 'GeoCoordinates',
      latitude: 54.2451036,
      longitude: 18.6256848,
    },
    openingHoursSpecification: {
      '@type': 'OpeningHoursSpecification',
      dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
      opens: '08:00',
      closes: '16:00',
    },
    sameAs: 'https://paletypruszczgdanski.pl/',
  };

  return (
    <Layout schemaMarkup={schema}>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.objectOf(
        PropTypes.oneOfType([PropTypes.object, PropTypes.string])
      ),
    }),
  }).isRequired,
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(
              width: 1600
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
        heading
        subheading
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 400
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
            title
            text
          }
          heading
          description
        }
      }
    }
  }
`;
