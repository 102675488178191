import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { gsap } from 'gsap';
import { TextPlugin } from 'gsap/TextPlugin';
import { getImage } from 'gatsby-plugin-image';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Slide from './Slide';

const SliderWrapper = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
  margin: 0 auto;
  overflow: hidden;
`;

const StyledBox = styled.div`
  position: absolute;
  top: 25%;
  left: 10%;
  display: block;
  padding: 0 20px;
  z-index: 15;
  ${({ theme }) => theme.mq.md} {
    padding: 0 100px;
  }
  ${({ theme }) => theme.mq.lg} {
    padding: 0 200px;
  }
`;

const StyledHeader = styled.h1`
  margin: 0 auto;
  text-align: left;
  color: ${({ theme }) => theme.color.one};
  font-size: ${({ theme }) => theme.font.size.sm};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-family: Poppins, sans-serif;
  line-height: 40px;
  ${({ theme }) => theme.mq.md} {
    font-size: ${({ theme }) => theme.font.size.l};
    line-height: 50px;
  }
  ${({ theme }) => theme.mq.xl} {
    font-size: ${({ theme }) => theme.font.size.l};
    line-height: 75px;
  }
`;

const StyledParagraph = styled.p`
  margin-top: 5px;
  color: ${({ theme }) => theme.color.one};
  font-size: ${({ theme }) => theme.font.size.s};
  ${({ theme }) => theme.mq.md} {
    margin-left: 10px;
    margin-top: 25px;
    font-size: ${({ theme }) => theme.font.size.sm};
  }
`;

const HeroCarousel = ({ slides }) => {
  let header = useRef(null);
  let description = useRef(null);
  const titles = [
    { name: 'Transport', description: 'Transport ciężarowy' },
    {
      name: 'Skład Palet',
      description:
        'Skup i sprzedaż palet. Plaety Euro, przemysłowe, połówki i inne',
    },
    { name: 'Skład Opału', description: 'Węgiel workowany, ekogroszek i inne' },
  ];

  const animateHeader = (oldIndex, newIndex) => {
    const timeline = gsap.timeline();
    gsap.registerPlugin(TextPlugin);
    timeline
      .set(header, { text: { value: titles[newIndex].name } })
      .fromTo(
        header,
        {
          x: -300,
          duration: 1,
          scale: 0.5,
        },
        {
          x: 0,
          scale: 1,
        }
      )
      .set(description, { text: { value: '' } })
      .set(description, { y: 0 })
      .to(description, {
        duration: 2,
        text: { value: titles[newIndex].description },
        ease: 'none',
      })
      .to(description, { y: 500, delay: 2.5, duration: 0.7 })
      .to(header, { x: 2000, duration: 0.5 });

    timeline.play();
  };
  useEffect(() => {
    animateHeader(0, 0);
  }, []);

  const settings = {
    infinite: true,
    speed: 1000,
    adaptiveHeight: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    cssEase: 'linear',
    pauseOnHover: true,
    fade: true,
    beforeChange: (oldIndex, newIndex) => animateHeader(oldIndex, newIndex),
  };
  return (
    <SliderWrapper>
      <Slider {...settings}>
        {slides.map((item) => {
          const image = getImage(item.node);
          return (
            <Slide image={image} title={item.node.name} key={item.node.id} />
          );
        })}
      </Slider>
      <StyledBox>
        <StyledHeader
          ref={(el) => {
            header = el;
          }}
        ></StyledHeader>
        <StyledParagraph
          ref={(el) => {
            description = el;
          }}
        ></StyledParagraph>
      </StyledBox>
    </SliderWrapper>
  );
};

HeroCarousel.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default HeroCarousel;
