import React from 'react';
import styled from 'styled-components';
import { Phone, Envelope, Clock, Home } from '@styled-icons/fa-solid';

const Header = styled.h2`
  font-size: 36px;
  margin-bottom: 25px;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
  color: ${({ theme }) => theme.color.four};
  ${({ theme }) => theme.mq.lg} {
    margin-bottom: 45px;
  }
`;

const ItemContainer = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 10px 0 0;
`;

const IconBox = styled.div`
  display: flex;
  margin-right: 15px;
  margin-top: 2px;
  color: ${({ theme }) => theme.color.one};
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${({ theme }) => theme.font.size.xs};
  color: ${({ theme }) => theme.color.one};
  ${({ theme }) => theme.mq.lg} {
    flex-direction: row;
  }
`;

const TextSpan = styled.span`
  color: ${({ theme }) => theme.color.four};
  margin-left: 0px;
  ${({ theme }) => theme.mq.lg} {
    margin-left: 10px;
  }
`;

const SyledLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.color.four};
  margin-left: 0px;
  &:hover {
    color: ${({ theme }) => theme.color.one};
  }
  &:active {
    color: ${({ theme }) => theme.color.one};
  }
  ${({ theme }) => theme.mq.lg} {
    margin-left: 10px;
  }
`;

const ContactData = () => {
  return (
    <>
      <Header>Dane teleadresowe</Header>
      <div>
        <ItemContainer>
          <IconBox>
            <Home size="25px" title="Adres" />
          </IconBox>
          <TextBox>
            Adres:
            <TextSpan>Pruszcz Gdański 83-000, Batalionów Chłopskich 3</TextSpan>
          </TextBox>
        </ItemContainer>
        <ItemContainer>
          <IconBox>
            <Phone size="25px" title="Telefon" />
          </IconBox>
          <TextBox>
            Telefon:
            <SyledLink aria-label="zadzwoń" href="tel:+48731461112">
              731 461 112
            </SyledLink>
          </TextBox>
        </ItemContainer>
        <ItemContainer>
          <IconBox>
            <Envelope size="25px" title="Email" />
          </IconBox>
          <TextBox>
            E-mail:
            <SyledLink
              aria-label="napisz"
              href="mailto:paletypruszczgdanski@gmail.com"
            >
              paletypruszczgdanski@gmail.com
            </SyledLink>
          </TextBox>
        </ItemContainer>
        <ItemContainer>
          <IconBox>
            <Clock size="25px" title="Godziny otwarcia" />
          </IconBox>
          <TextBox>
            Godziny otwarcia:
            <TextSpan>Pon. - Piąt. 08.00 - 16.00</TextSpan>
          </TextBox>
        </ItemContainer>
      </div>
    </>
  );
};

export default ContactData;
