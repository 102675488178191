import React from 'react';
import styled from 'styled-components';

import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';

const StyledSlide = styled.div`
  height: 100vh;
  object-fit: cover;
  position: relative;
`;

const Slide = ({ image, title }) => {
  return (
    <StyledSlide>
      <GatsbyImage
        image={image}
        alt={title}
        style={{
          width: '100%',
          height: '100%',
          margin: '0 auto',
          objectFit: 'cover',
          mixBlendMode: 'luminosity',
        }}
      />
    </StyledSlide>
  );
};

Slide.propTypes = {
  image: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.string])
  ).isRequired,
  title: PropTypes.string.isRequired,
};

export default Slide;
