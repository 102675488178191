import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';

import PreviewCompatibleImage from './PreviewCompatibleImage';
import SectionHeader from './SectionHeader';

const FeatureSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  padding: 5px;
  ${({ theme }) => theme.mq.lg} {
    flex-direction: row;
    padding: 50px;
  }
`;

const FeatureContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 10px;
  ${({ theme }) => theme.mq.lg} {
    width: calc(100vw / 3 - 100px);
  }
`;

const Article = styled.article`
  text-align: center;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  ${({ theme }) => theme.mq.md} {
    width: 80%;
    height: 250px;
  }
  ${({ theme }) => theme.mq.lg} {
    width: 100%;
    height: 200px;
  }
`;

const FeatureContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const FeatureTitle = styled.h2`
  font-size: ${({ theme }) => theme.font.size.s};
  margin: 10px 0;
  font-weight: 700;
  color: ${({ theme }) => theme.color.four};
  position: relative;
`;

const FeatureParagraph = styled.p`
  font-size: ${({ theme }) => theme.font.size.xxs};
  color: ${({ theme }) => theme.color.five};
`;

const FeatureGrid = ({ gridItems }) => {
  const cards = useRef([]);

  cards.current = [];

  const addToRefs = (el) => {
    if (el && !cards.current.includes(el)) {
      cards.current.push(el);
    }
  };

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.set(cards.current, { autoAlpha: 0, scale: 0.9 });
    ScrollTrigger.batch(cards.current, {
      start: 'top bottom-=100px',
      onEnter: (batch) =>
        gsap.to(batch, {
          duration: 0.8,
          autoAlpha: 1,
          scale: 1,
          ease: 'none',
          stagger: 0.3,
        }),
      onLeaveBack: (batch) =>
        gsap.to(batch, {
          duration: 0.5,
          autoAlpha: 0,
          scale: 0.9,
          stagger: 0.1,
        }),
    });
  }, []);

  return (
    <FeatureSection id="oferta">
      <SectionHeader
        title="Nasze usługi"
        subtitle="Prowadzimy aktywną sprzedaż oraz skup wszystkich rodzajów palet. Dysponujemy własnym transportem ciężarowym, od 12 tonowych cięzarówek do 40 tonowych ciągników z naczepą. Jesteśmy dystrybutorem opału: ekogroszek, orzech workowany itp."
      />
      <Wrapper>
        {gridItems.map((item) => (
          <FeatureContainer ref={addToRefs} key={item.title}>
            <Article>
              <ImageContainer>
                <PreviewCompatibleImage imageInfo={item} />
              </ImageContainer>
              <FeatureContent>
                <FeatureTitle>{item.title}</FeatureTitle>
                <FeatureParagraph>{item.text}</FeatureParagraph>
              </FeatureContent>
            </Article>
          </FeatureContainer>
        ))}
      </Wrapper>
    </FeatureSection>
  );
};

FeatureGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
    })
  ).isRequired,
};

export default FeatureGrid;
