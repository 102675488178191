import Spinner from 'react-spinner-material';
import React from 'react';

const Loading = () => {
  return (
    <div>
      <Spinner size={120} color="#61D2B4" stroke={2} visible />
    </div>
  );
};

export default Loading;
