import React, { lazy, Suspense, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';

import Loading from './Loading';
import SectionHeader from './SectionHeader';
import ContactForm from './ContactForm';
import ContactData from './ContactData';
import ContactSocialMedia from './ContactSocialMedia';

const AccessMap = lazy(() => import('./AccessMap'));
const renderLoader = () => <Loading />;

const ContactSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  padding: 5px;
  background-color: ${({ theme }) => theme.color.five};
  ${({ theme }) => theme.mq.lg} {
    flex-direction: row;
    padding: 50px;
  }
`;

const ContactContainer = styled.div`
  display: block;
  color: ${({ theme }) => theme.color.one};
  width: 100%;
`;

const Container = styled.div`
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
  max-width: 540px;
  ${({ theme }) => theme.mq.md} {
    max-width: 720px;
  }
  ${({ theme }) => theme.mq.lg} {
    max-width: 960px;
  }
  ${({ theme }) => theme.mq.xl} {
    max-width: 1140px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-right: -15px;
  margin-left: -15px;
  margin-bottom: 20px;
`;

const Block = styled.div`
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  visibility: hidden;
  ${({ theme }) => theme.mq.sm} {
    flex: 0 0 50%;
    max-width: 50%;
  }
  ${({ theme }) => theme.mq.lg} {
    flex: 0 0 50%;
    max-width: 50%;
  }
`;

const Contact = () => {
  let block1 = useRef(null);
  let block2 = useRef(null);
  let block3 = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.set(block1, { autoAlpha: 0, y: 50 });
    gsap.set(block2, { autoAlpha: 0, y: 50 });
    gsap.set(block3, { autoAlpha: 0, y: 50 });
    gsap.to(block1, {
      scrollTrigger: block1,
      autoAlpha: 1,
      duration: 1,
      y: 0,
    });
    gsap.to(block2, {
      scrollTrigger: block2,
      autoAlpha: 1,
      duration: 1,
      y: 0,
    });
    gsap.to(block3, {
      scrollTrigger: block3,
      autoAlpha: 1,
      duration: 1,
      y: 0,
    });
  }, []);
  const isSSR = typeof window === 'undefined';
  return (
    <ContactSection id="contact">
      <SectionHeader
        title="Zapraszamy do kontaktu"
        subtitle="Skontaktuj się z nami za pośrednictwem telefonu, emaila, social mediów lub formularza kontaktowego."
        color="#fff"
      />
      <ContactContainer>
        <Container>
          <Row>
            <Block
              ref={(el) => {
                block1 = el;
              }}
            >
              <ContactData />
              <ContactSocialMedia />
            </Block>
            <Block
              ref={(el) => {
                block2 = el;
              }}
            >
              <ContactForm />
            </Block>
          </Row>
          <Row>
            <Block
              ref={(el) => {
                block3 = el;
              }}
            >
              {!isSSR && (
                <Suspense fallback={renderLoader()}>
                  <AccessMap />
                </Suspense>
              )}
            </Block>
          </Row>
        </Container>
      </ContactContainer>
    </ContactSection>
  );
};

export default Contact;
