import React from 'react';

import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

import HeroCarousel from './HeroCarousel';

const StyledWrapper = styled.section`
  display: flex;
  align-items: center;
  min-height: 60vh;
  background: transparent;
  overflow: hidden;
  position: relative;
`;

const StyledBackgroundLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 13;
  background: rgba(51, 179, 145, 0.5);
`;

const SliderContainer = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
`;

const Hero = () => {
  const { allFile } = useStaticQuery(
    graphql`
      query {
        allFile(
          sort: { fields: name, order: DESC }
          filter: { relativeDirectory: { eq: "slides" } }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                gatsbyImageData(
                  width: 1920
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    `
  );

  const { edges } = allFile;

  return (
    <StyledWrapper id="home">
      <SliderContainer>
        <HeroCarousel slides={edges} />
      </SliderContainer>
      <StyledBackgroundLayer />
    </StyledWrapper>
  );
};

export default Hero;
