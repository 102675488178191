import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Features from '../components/Features';
import BlogRoll from '../components/BlogRoll';
import Hero from '../components/Hero';
import Contact from '../components/Contact';

import Anchorlink from '../components/Anchorlink';

const LinkContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;

const IndexPageTemplate = ({ intro }) => (
  <div>
    <Hero />
    <Features gridItems={intro.blurbs} />
    <BlogRoll />
    <LinkContainer>
      <Anchorlink ariaLabel="więcej artykułów" to="/blog">
        Więcej artykułów
      </Anchorlink>
    </LinkContainer>
    <Contact />
  </div>
);

IndexPageTemplate.propTypes = {
  intro: PropTypes.shape({
    blurbs: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

export default IndexPageTemplate;
